import './App.css';
import React, {useState, useEffect} from 'react';
import logo from './images/logo_innovationmatters_black.png';
import small_logo from './images/logo_innovationmatters_weissFINAL.png';
import useWebSocket, { ReadyState } from 'react-use-websocket';

var sentName = false;

function App() {
    // WEBSOCKET
    const [socketUrl, setSocketUrl] = useState('wss://tech.im-iot.com/ws');
    //const [socketUrl, setSocketUrl] = useState('ws://192.168.0.245:8080/ws');
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {shouldReconnect: (closeEvent) => true});

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    // TASTER
    const [activeTK1, setActiveTK1] = useState(false);
    const [activeTK2, setActiveTK2] = useState(false);
    const [activeTK3, setActiveTK3] = useState(false);
    const [activeTK4, setActiveTK4] = useState(false);

    // User has switched back to the tab
    const onFocus = () => {
        sentName = false;
    };
    window.addEventListener("focus", onFocus);

    useEffect(() => {
        if (sentName === false){
            sendMessage("Web");
            sentName = true;
        }

        if (lastMessage!== null ){
            // console.log("LASTMESSAGE: " + lastMessage.data);
            switch (lastMessage.data) {
                case "Pressed:  1":
                    setActiveTK1(true);
                    setTimeout(function(){
                        setActiveTK1(false);
                    }, 2000);
                    break;

                case "Pressed:  2":
                    setActiveTK2(true);
                    setTimeout(function(){
                        setActiveTK2(false);
                    }, 2000);
                    break;

                case "Pressed:  3":
                    setActiveTK3(true);
                    setTimeout(function(){
                        setActiveTK3(false);
                    }, 2000);
                    break;

                case "Pressed:  4":
                    setActiveTK4(true);
                    setTimeout(function(){
                        setActiveTK4(false);
                    }, 2000);
                    break;
            }
        }

    }, [lastMessage]);

    const Buttons = (props) => {
        if (props.connectionStatus) {
            return (
                <div className="ButtonBox">
                    <p>Press buttons on the Tasterkoppler to see the connection.</p>
                    <button className="ButtonStyle" style={{backgroundColor: activeTK1 ? '#1a721a':'#9f4d4d'}} >Input 1</button>
                    <button className="ButtonStyle" style={{backgroundColor: activeTK2 ? '#1a721a':'#9f4d4d'}} >Input 2</button>
                    <button className="ButtonStyle" style={{backgroundColor: activeTK3 ? '#1a721a':'#9f4d4d'}} >Input 3</button>
                    <button className="ButtonStyle" style={{backgroundColor: activeTK4 ? '#1a721a':'#9f4d4d'}} >Input 4</button>
                </div>
            );
        }
    };

    const InfoBox = () => {
       return(
            <div className="InfoBox">
            How to use:
            <ul>
                <li>
                    1. Create a hotspot on your phone with the following credentials:
                    <ul>
                        <li>
                            - <b>SSID: TK-Testing</b>
                        </li>
                        <li>
                            - <b>PW: IMIG2022</b>
                        </li>
                    </ul>
                </li>
                <li>
                    2. Wire the Tasterkoppler.
                </li>
                <li>
                    3. Power up the device and wait ~10 seconds.
                </li>
                <li>
                    4. Press buttons to see the functionality.
                </li>
            </ul>
        </div>
       )
    };

    const Header = () => {
        return (
            <div className="Header">
                <img src={small_logo} className="Small-Logo" alt="small logo" />
            </div>
        )
    };

    const ConnectionBox = (props) => {
        return (
            <div>
                <b>Websocket connection: {connectionStatus}</b>
            </div>
        )
    };

    return (
        <div className="App">

            <body className="App-main">
                <img src={logo} className="App-logo" alt="LOGO" />
                <InfoBox></InfoBox>
                <ConnectionBox></ConnectionBox>
                <Buttons connectionStatus={connectionStatus == "Open"}></Buttons>
            </body>

        </div>
    );
}

export default App;
